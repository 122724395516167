<template>
  <v-dialog v-model="visible" max-width="512">
    <v-card>
      <v-card-title
        ><b><h3>Add Mission</h3></b></v-card-title
      >
      <v-card-text>
        <p v-if="localMissions.length === 0">Fetching missions</p>
        <template v-else>
          <v-autocomplete
            v-model="localMissionID"
            :items="localMissions"
            label="Title"
            item-value="theKey"
            item-text="name"
          ></v-autocomplete>
          <v-select
            v-model="missionID"
            label="Place after"
            :items="missions"
            item-value="theKey"
            item-text="name"
          >
            <template #item="{ item }">{{ item.name }}</template>
          </v-select>
        </template>
      </v-card-text>
      <v-card-actions v-if="missionID && localMissionID">
        <v-spacer />
        <v-btn @click="submitHandler" color="primary">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue"
import { uniqBy, flatten } from "lodash"

import { fetchGamesByRunStatus, fetchMissions } from "@/services/game.service"

import { ModalMixin } from "../../mixins/Modal"

import { Game } from "@/helpers"

export default Vue.extend({
  name: "MissionAddModal",
  mixins: [ModalMixin],
  props: {
    missions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      missionID: "",
      localMissionID: "",
      localMissions: []
    }
  },
  computed: {},
  async mounted() {
    const { orgID } = this.$store.getters
    const games = await fetchGamesByRunStatus({
      orgID,
      value: Game.GAME_RUN_STATUS.MASTERS
    })

    const IDs = Object.keys(games)
    const promises = IDs.map(gameID => fetchMissions({ orgID, gameID }))
    const res = await Promise.all(promises)
    this.localMissions = uniqBy(
      flatten(
        res.map(missions => Object.values(missions)),
        "theKey"
      ),
      "instructions"
    )
  },
  methods: {
    titlePredicate(mission) {
      return mission.title
    },
    submitHandler() {
      const { missionID, localMissionID } = this
      this.$emit("create", {
        afterID: missionID,
        mission: this.getLocalMission(localMissionID)
      })
      this.closeModal()
    },
    getLocalMission(id) {
      return this.localMissions.find(mission => mission.theKey === id)
    }
  }
})
</script>

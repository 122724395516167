var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        [
          _c(
            "h2",
            { staticClass: "black--text" },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.game.name) +
                  " - " +
                  _vm._s(_vm.game.description) +
                  " "
              ),
              _c(
                "v-btn",
                {
                  staticClass: "sortHandle",
                  attrs: { icon: "" },
                  on: { click: _vm.pushToGame }
                },
                [_c("v-icon", [_vm._v("flight_takeoff")])],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "sortHandle",
                  attrs: { icon: "" },
                  on: { click: _vm.pushToGameUsers }
                },
                [_c("v-icon", [_vm._v("face")])],
                1
              ),
              false
                ? _c(
                    "v-btn",
                    {
                      staticClass: "sortHandle",
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.turnOffVideo()
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("visibility_off")])],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c("v-spacer"),
          _c("v-text-field", {
            attrs: {
              "append-icon": "search",
              label: "Search",
              "single-line": "",
              "hide-details": ""
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        ],
        1
      ),
      _c(
        "v-btn",
        {
          attrs: {
            circle: "",
            absolute: "",
            top: "",
            small: "",
            right: "",
            fab: "",
            color: "orange"
          },
          on: {
            click: function($event) {
              return _vm.add()
            }
          }
        },
        [_c("v-icon", [_vm._v("add")])],
        1
      ),
      _c(
        "v-btn",
        {
          attrs: {
            circle: "",
            absolute: "",
            top: "",
            small: "",
            right: "",
            fab: "",
            color: "blue"
          },
          on: {
            click: function($event) {
              return _vm.showInstructions()
            }
          }
        },
        [_c("v-icon", [_vm._v("flash_on")])],
        1
      ),
      _c(
        "v-btn",
        {
          attrs: {
            circle: "",
            absolute: "",
            top: "",
            small: "",
            left: "",
            fab: "",
            color: "orange"
          },
          on: {
            click: function($event) {
              _vm.isMisssionAddModalVisible = true
            }
          }
        },
        [_c("v-icon", [_vm._v("add")])],
        1
      ),
      _c("v-data-table", {
        ref: "sortableTable",
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.allMissions,
          search: _vm.search,
          "item-key": "theKey",
          length: "10",
          dense: ""
        },
        scopedSlots: _vm._u([
          {
            key: "items",
            fn: function(props) {
              return [
                _c(
                  "tr",
                  {
                    key: _vm.itemKey(props.item),
                    staticClass: "sortableRow",
                    attrs: { id: _vm.behaviorSafe(props.item.behavior) },
                    on: {
                      click: function($event) {
                        props.expanded = !props.expanded
                      }
                    }
                  },
                  [
                    _c(
                      "td",
                      { staticClass: "px-1", staticStyle: { width: "0.1%" } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "sortHandle",
                            staticStyle: { cursor: "move" },
                            attrs: { icon: "" }
                          },
                          [_c("v-icon", [_vm._v("drag_handle")])],
                          1
                        )
                      ],
                      1
                    ),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.pos))
                    ]),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.name))
                    ]),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.behavior))
                    ]),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.points))
                    ]),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            props.item.instructions.substring(
                              0,
                              _vm.instructionsLength
                            )
                          ) +
                          " "
                      )
                    ]),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.formatPlayType(props.item.playType)) +
                          " "
                      )
                    ]),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.numOfTries))
                    ]),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(" " + _vm._s(_vm.modeLetters(props.item)) + " ")
                    ]),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.time))
                    ]),
                    _c(
                      "td",
                      { staticClass: "text-xs-center", attrs: { nowrap: "" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              link: "",
                              color: "white",
                              light: "",
                              small: "",
                              fab: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.edit(props.item)
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("edit")])],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              link: "",
                              color: "white",
                              light: "",
                              small: "",
                              fab: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.copy(props.item)
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("open_in_new")])],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              link: "",
                              color: "white",
                              light: "",
                              small: "",
                              fab: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.openRemoveDialog(props.item)
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("delete")])],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            }
          },
          {
            key: "pageText",
            fn: function(ref) {
              var pageStart = ref.pageStart
              var pageStop = ref.pageStop
              return [
                _vm._v(
                  " From " + _vm._s(pageStart) + " to " + _vm._s(pageStop) + " "
                )
              ]
            }
          }
        ])
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.deleting,
            callback: function($$v) {
              _vm.deleting = $$v
            },
            expression: "deleting"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Delete Mission")]),
              _c("v-card-text", [
                _vm._v("Are you sure want to delete this mission?")
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "error",
                          attrs: { flat: "" },
                          on: { click: _vm.remove }
                        },
                        [_vm._v("Remove")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "secondary",
                          attrs: { flat: "" },
                          on: {
                            click: function($event) {
                              _vm.deleting = false
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("mission-add-modal", {
        attrs: { missions: _vm.allMissions },
        on: { create: _vm.onCreateMission },
        model: {
          value: _vm.isMisssionAddModalVisible,
          callback: function($$v) {
            _vm.isMisssionAddModalVisible = $$v
          },
          expression: "isMisssionAddModalVisible"
        }
      }),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", persistent: "", width: "850px" },
          model: {
            value: _vm.editing,
            callback: function($$v) {
              _vm.editing = $$v
            },
            expression: "editing"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "highlight", dark: "" } },
                    [
                      _c("v-icon", { attrs: { dark: "" } }, [_vm._v("edit")]),
                      _c("v-toolbar-title", [
                        _vm._v(" " + _vm._s(_vm.mission.name))
                      ]),
                      _c("v-spacer"),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        { attrs: { icon: "" }, on: { click: _vm.update } },
                        [_c("v-icon", [_vm._v("saves")])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              _vm.editing = false
                              _vm.copying = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("cancel")])],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.mission
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", "mx-2": "" } },
                                _vm._l(_vm.allModes(_vm.mission), function(
                                  mode,
                                  i
                                ) {
                                  return _c("v-checkbox", {
                                    key: i,
                                    staticClass: "missionCB",
                                    attrs: { label: mode.name },
                                    model: {
                                      value: _vm.mission[mode.name],
                                      callback: function($$v) {
                                        _vm.$set(_vm.mission, mode.name, $$v)
                                      },
                                      expression: "mission[mode.name]"
                                    }
                                  })
                                }),
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm5: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Name", required: "" },
                                    model: {
                                      value: _vm.mission.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.mission, "name", $$v)
                                      },
                                      expression: "mission.name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm5: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Title", required: "" },
                                    model: {
                                      value: _vm.mission.title,
                                      callback: function($$v) {
                                        _vm.$set(_vm.mission, "title", $$v)
                                      },
                                      expression: "mission.title"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm1: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Points", required: "" },
                                    model: {
                                      value: _vm.mission.points,
                                      callback: function($$v) {
                                        _vm.$set(_vm.mission, "points", $$v)
                                      },
                                      expression: "mission.points"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "instruction",
                                  attrs: { xs12: "", "mx-2": "" }
                                },
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      name: "instructions",
                                      rows: "3",
                                      label: "Instructions",
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.mission.instructions,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.mission,
                                          "instructions",
                                          $$v
                                        )
                                      },
                                      expression: "mission.instructions"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticStyle: { "margin-top": "-50px" },
                                  attrs: { xs6: "", "mx-2": "" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Notes", notes: "" },
                                    model: {
                                      value: _vm.mission.notes,
                                      callback: function($$v) {
                                        _vm.$set(_vm.mission, "notes", $$v)
                                      },
                                      expression: "mission.notes"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticStyle: { "margin-top": "-50px" },
                                  attrs: { xs2: "" }
                                },
                                [
                                  _c("v-checkbox", {
                                    attrs: { label: "Pass Scribe" },
                                    model: {
                                      value: _vm.mission.passScribe,
                                      callback: function($$v) {
                                        _vm.$set(_vm.mission, "passScribe", $$v)
                                      },
                                      expression: "mission.passScribe"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticStyle: { "margin-top": "-50px" },
                                  attrs: { xs2: "" }
                                },
                                [
                                  _c("v-checkbox", {
                                    attrs: { label: "No Vote" },
                                    model: {
                                      value: _vm.mission.noVote,
                                      callback: function($$v) {
                                        _vm.$set(_vm.mission, "noVote", $$v)
                                      },
                                      expression: "mission.noVote"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", "mx-2": "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.behaviors,
                                      label: "Behaviors",
                                      required: ""
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.defaultModes($event)
                                      }
                                    },
                                    model: {
                                      value: _vm.mission.behavior,
                                      callback: function($$v) {
                                        _vm.$set(_vm.mission, "behavior", $$v)
                                      },
                                      expression: "mission.behavior"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm1: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Time Limit (seconds)",
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.mission.time,
                                      callback: function($$v) {
                                        _vm.$set(_vm.mission, "time", $$v)
                                      },
                                      expression: "mission.time"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm4: "", "mx-2": "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.playTypes,
                                      label: "Play Types",
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.mission.playType,
                                      callback: function($$v) {
                                        _vm.$set(_vm.mission, "playType", $$v)
                                      },
                                      expression: "mission.playType"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm2: "", "mx-2": "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.tries,
                                      label: "Max Tries"
                                    },
                                    model: {
                                      value: _vm.mission.numOfTries,
                                      callback: function($$v) {
                                        _vm.$set(_vm.mission, "numOfTries", $$v)
                                      },
                                      expression: "mission.numOfTries"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.mission.behavior == "Fact Match"
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "mx-5": "" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.freeFormMissions,
                                          "item-text": "name",
                                          "item-value": "id",
                                          label:
                                            "Connect to Free Form, Photo, Take Photo or Giphy"
                                        },
                                        model: {
                                          value: _vm.mission.freeFormMissionID,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.mission,
                                              "freeFormMissionID",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "mission.freeFormMissionID"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.mission.behavior === "Take Photo" ||
                              _vm.mission.behavior === "Photo"
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs8: "", "mx-2": "" } },
                                    [
                                      _c("v-checkbox", {
                                        attrs: { label: "Use for Avatar" },
                                        model: {
                                          value: _vm.mission.useAsAvatar,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.mission,
                                              "useAsAvatar",
                                              $$v
                                            )
                                          },
                                          expression: "mission.useAsAvatar"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.mission.behavior === "Link"
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs8: "", "mx-2": "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: { label: "Link URL" },
                                        model: {
                                          value: _vm.mission.answer,
                                          callback: function($$v) {
                                            _vm.$set(_vm.mission, "answer", $$v)
                                          },
                                          expression: "mission.answer"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.mission.behavior === "Link"
                                ? _c(
                                    "v-flex",
                                    {
                                      staticStyle: { "margin-top": "-5px" },
                                      attrs: { xs3: "" }
                                    },
                                    [
                                      _c("v-checkbox", {
                                        attrs: { label: "Show Winning Team" },
                                        model: {
                                          value: _vm.mission.showWinningTeam,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.mission,
                                              "showWinningTeam",
                                              $$v
                                            )
                                          },
                                          expression: "mission.showWinningTeam"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "transition",
                                { attrs: { name: "fade" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "mx-2": "" } },
                                    [
                                      _vm.mission.behavior === "Text" ||
                                      _vm.mission.behavior ===
                                        "Draw: Pictionary" ||
                                      _vm.mission.behavior === "Categories" ||
                                      _vm.mission.behavior === "Lipdub"
                                        ? _c("v-text-field", {
                                            attrs: { label: "Answer" },
                                            model: {
                                              value: _vm.mission.answer,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.mission,
                                                  "answer",
                                                  $$v
                                                )
                                              },
                                              expression: "mission.answer"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.mission.behavior === "Multiple Choice" ||
                              _vm.mission.behavior === "Poll"
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "mx-2": "" } },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { row: "" } },
                                        [
                                          _vm.mission.behavior ===
                                          "Multiple Choice"
                                            ? _c(
                                                "v-flex",
                                                { attrs: { xs10: "" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Answer 1"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.mission.answer1,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.mission,
                                                          "answer1",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "mission.answer1"
                                                    }
                                                  }),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Answer 2"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.mission.answer2,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.mission,
                                                          "answer2",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "mission.answer2"
                                                    }
                                                  }),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Answer 3"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.mission.answer3,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.mission,
                                                          "answer3",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "mission.answer3"
                                                    }
                                                  }),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Answer 4"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.mission.answer4,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.mission,
                                                          "answer4",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "mission.answer4"
                                                    }
                                                  }),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Answer 5"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.mission.answer5,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.mission,
                                                          "answer5",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "mission.answer5"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _c(
                                                "v-flex",
                                                { attrs: { xs10: "" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Option 1"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.mission.answer1,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.mission,
                                                          "answer1",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "mission.answer1"
                                                    }
                                                  }),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Option 2"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.mission.answer2,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.mission,
                                                          "answer2",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "mission.answer2"
                                                    }
                                                  }),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Option 3"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.mission.answer3,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.mission,
                                                          "answer3",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "mission.answer3"
                                                    }
                                                  }),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Option 4"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.mission.answer4,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.mission,
                                                          "answer4",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "mission.answer4"
                                                    }
                                                  }),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Option 5"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.mission.answer5,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.mission,
                                                          "answer5",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "mission.answer5"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                          _vm.mission.behavior ===
                                          "Multiple Choice"
                                            ? _c(
                                                "v-flex",
                                                {
                                                  attrs: { xs2: "", "mx-3": "" }
                                                },
                                                [
                                                  _c(
                                                    "v-radio-group",
                                                    {
                                                      model: {
                                                        value:
                                                          _vm.mission
                                                            .multiCorrect,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.mission,
                                                            "multiCorrect",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "mission.multiCorrect"
                                                      }
                                                    },
                                                    _vm._l(5, function(n) {
                                                      return _c("v-radio", {
                                                        key: n,
                                                        staticClass: "correct",
                                                        attrs: {
                                                          value: n,
                                                          "my-5": "",
                                                          label: "correct"
                                                        }
                                                      })
                                                    }),
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-flex",
                                { attrs: { xs2: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "YouTube Link" },
                                    model: {
                                      value: _vm.mission.youtube,
                                      callback: function($$v) {
                                        _vm.$set(_vm.mission, "youtube", $$v)
                                      },
                                      expression: "mission.youtube"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs3: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Start time (secs)" },
                                    model: {
                                      value: _vm.mission.videoTime,
                                      callback: function($$v) {
                                        _vm.$set(_vm.mission, "videoTime", $$v)
                                      },
                                      expression: "mission.videoTime"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs3: "", "mx-2": "" } },
                                [
                                  _c("image-uploader", {
                                    attrs: {
                                      debug: 1,
                                      "max-width": 1200,
                                      quality: 1,
                                      "auto-rotate": true,
                                      preview: false,
                                      "class-name": [
                                        "fileinput",
                                        {
                                          "fileinput--loaded": _vm.mission.photo
                                        }
                                      ],
                                      "output-format": "verbose",
                                      capture: "environment",
                                      accept: ".gif,.jpg,.png,.jpeg"
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.setImage($event, _vm.game)
                                      }
                                    }
                                  }),
                                  _c("v-text-field", {
                                    attrs: { label: "Photo URL" },
                                    model: {
                                      value: _vm.mission.photo,
                                      callback: function($$v) {
                                        _vm.$set(_vm.mission, "photo", $$v)
                                      },
                                      expression: "mission.photo"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs3: "", "mx-2": "" } },
                                [
                                  _c("image-uploader", {
                                    attrs: {
                                      debug: 1,
                                      "max-width": 600,
                                      quality: 1,
                                      "auto-rotate": true,
                                      preview: false,
                                      "class-name": [
                                        "fileinput",
                                        {
                                          "fileinput--loaded":
                                            _vm.mission.instructionsPhoto
                                        }
                                      ],
                                      "output-format": "verbose",
                                      capture: "environment",
                                      accept: ".gif,.jpg,.png,.jpeg"
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.setInstructionsImage(
                                          $event,
                                          _vm.game
                                        )
                                      }
                                    }
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Instructions Image or Gif"
                                    },
                                    model: {
                                      value: _vm.mission.instructionsPhoto,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.mission,
                                          "instructionsPhoto",
                                          $$v
                                        )
                                      },
                                      expression: "mission.instructionsPhoto"
                                    }
                                  })
                                ],
                                1
                              ),
                              true
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs2: "", "mx-2": "" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.gameAudio,
                                          "item-text": "name",
                                          "item-value": "key",
                                          label: "Audio"
                                        },
                                        model: {
                                          value: _vm.mission.audio,
                                          callback: function($$v) {
                                            _vm.$set(_vm.mission, "audio", $$v)
                                          },
                                          expression: "mission.audio"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.copying && _vm.user.super
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "mx-2": "" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.orgs,
                                          label: "Choose Org",
                                          "item-text": "name",
                                          "item-value": "id"
                                        },
                                        on: { change: _vm.updateOrgID },
                                        model: {
                                          value: _vm.newOrgID,
                                          callback: function($$v) {
                                            _vm.newOrgID = $$v
                                          },
                                          expression: "newOrgID"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.copying
                                ? _c("v-flex", {
                                    attrs: { xs12: "", "mx-2": "" }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "" } },
                    [
                      _vm.copying
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "primary",
                              attrs: { flat: "" },
                              on: { click: _vm.update }
                            },
                            [_vm._v("Copy")]
                          )
                        : _c(
                            "v-btn",
                            {
                              staticClass: "primary",
                              attrs: { flat: "", loading: _vm.loadingImage },
                              on: { click: _vm.update }
                            },
                            [_vm._v("Save")]
                          ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "secondary",
                          attrs: { flat: "" },
                          on: {
                            click: function($event) {
                              _vm.editing = false
                              _vm.copying = false
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-flex", [_c("Audio", { attrs: { xs12: "" } })], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
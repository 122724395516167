<template>
  <v-flex center>
    <vue-plyr id="audio-player" ref="audio">
      <audio>
        <source :src="audioSrc" type="audio/mp3" />
      </audio>
    </vue-plyr>

    <v-dialog v-model="uploadModal" max-width="300px">
      <v-card height="350">
        <v-card-title primary-title>
          <div id="dialogue-card">
            <h1 class="headline center">Upload Audio</h1>
            <br />
          </div>
          <v-text-field
            id="audioname"
            v-model="audioname"
            name="audioname"
            label="Audio Name"
            type="text"
            required
          />
          <input
            type="file"
            accept=".m4a,.mp3,.ogg"
            @change="detectFiles($event.target.files)"
          />
          <div :style="{ width: progressUpload + '%' }" class="progress-bar" />
          <v-progress-linear v-model="progressUpload" />
        </v-card-title>
      </v-card>
    </v-dialog>

    <v-dialog v-model="edit" max-width="350px">
      <v-card pa4>
        <v-card-title primary-title>
          <div id="dialogue-card">
            <h1 class="headline center">Edit Audio</h1>
            <br />
            <v-text-field
              id="audioname"
              v-model="theAudio.name"
              name="audioname"
              label="Audio Name"
              type="text"
              required
            />
            <v-text-field
              id="audioname"
              v-model="theAudio.keypress"
              name="keypress"
              label="Keypress"
              type="text"
              required
            />
            <v-text-field
              id="audioname"
              v-model="theAudio.color"
              name="color"
              label="Hex Color"
              type="text"
            />
            <v-flex xs12
              ><v-slider v-model="theAudio.volume" label="volume"
            /></v-flex>
            <v-checkbox
              v-model="copyToUserAudio"
              :label="
                modalFrom !== 'userAudio'
                  ? 'Copy to user audio'
                  : 'Copy to game audio '
              "
            ></v-checkbox>
            <v-flex v-if="copyToUserAudio">
              <v-input v-model="copyToGameID" label="Game" required />
            </v-flex>
          </div>
        </v-card-title>
        <v-card-actions>
          <v-layout justify-center>
            <v-btn @click="edit = false">Cancel</v-btn>
            <v-btn class="primary" @click="saveAudio()">Save</v-btn><br /><br />
          </v-layout>
          <v-card-actions>
            <v-btn icon @click="deleteAudio()">
              <v-icon>delete</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div />
    <br style="clear: both" />
    <v-toolbar color="brown darken-3" dark height="40">
      <v-btn
        icon
        @click="
          audioname = ''
          userSound = false
          uploadModal = true
          theAudio = {}
          progressUpload = 0
        "
      >
        <v-icon>add</v-icon>
      </v-btn>
      <v-toolbar-title>Game Music Clips</v-toolbar-title>
      <v-spacer />
      <v-btn icon>
        <v-icon>search</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card class="audio-button" min-height="100" width="100%">
      <v-btn
        v-for="(item, index) in audioArr"
        v-model="item.source"
        :key="index"
        :style="`background-color: ${item.color}; margin: 3px`"
        :class="{ activeSound: activeBtn === index }"
        dark
        class=""
        @click.shift="editAudio(item, index, 'gameAudio')"
        @click.alt="editAudio(item, index, 'gameAudio')"
        @click.exact="setAudio(item, index)"
      >
        {{ item.name }}
      </v-btn>
    </v-card>
    <br style="clear: both" />
    <v-toolbar color="purple darken-3" dark height="40">
      <v-btn
        icon
        @click="
          audioname = ''
          userSound = true
          uploadModal = true
          theAudio = {}
          progressUpload = 0
        "
      >
        <v-icon>add</v-icon>
      </v-btn>
      <v-toolbar-title>My User Clips</v-toolbar-title>
      <v-spacer />
      <v-btn icon>
        <v-icon>search</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card class="audio-button" min-height="100" width="100%">
      <v-btn
        v-for="(item, index) in audioUserArr"
        v-model="item.source"
        :key="index"
        :style="`background-color: ${item.color}; margin: 3px`"
        :class="{ activeSound: activeBtn === index }"
        dark
        class=""
        @click.alt="editAudio(item, index, 'userAudio')"
        @click.shift="editAudio(item, index, 'userAudio')"
        @click.exact="setAudio(item, index)"
      >
        {{ item.name }}
      </v-btn>
    </v-card>
  </v-flex>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import { uploadMedia } from "@/services/storage.service"

import firebase from "firebase/app"

export default {
  data() {
    return {
      file: "http://www.noiseaddicts.com/samples_1w72b820/290.mp3",
      audioSrc: null,
      progressUpload: 0,
      // file: File,
      audioname: null,
      audioArr: [],
      audioUserArr: [],
      uploadModal: false,
      theAudio: {},
      edit: false,
      activeBtn: null,
      userSound: false,
      copyToUserAudio: false,
      modalFrom: null,
      copyToGameID: null
    }
  },
  created() {
    this.$store.dispatch("subscribeToAudioPlayer")
  },
  mounted() {
    this.loadAudio()
  },
  computed: {
    ...mapGetters("auth", ["token"]),
    ...mapState({
      audioPlayerData: state => state.mission.audioPlayer,
      gameID: state => state.gameID,
      orgID: state => state.orgID,
      user: state => state.auth.user
    })
  },
  methods: {
    editAudio(a, index, modalFrom) {
      this.theAudio = a
      this.theAudio.key = index
      console.log(a)
      this.edit = true
      this.modalFrom = modalFrom
    },
    saveAudio() {
      console.log("save audio")
      var thePath
      var gameID = this.gameID
      if (this.modalFrom === "userAudio") {
        thePath = "org/" + this.orgID
        if (this.copyToUserAudio) {
          if (this.copyToGameID) gameID = this.copyToGameID
          const audioData = Object.assign({}, this.theAudio)
          const ref = firebase
            .database()
            .ref(`org/${this.orgID}/game/${gameID}/audio`)
            .push()
          audioData.key = ref.key
          ref.update(audioData)
        }
      } else {
        thePath = "org/" + this.orgID + "/game/" + this.gameID
        if (this.copyToUserAudio) {
          if (this.copyToGameID) gameID = this.copyToGameID
          const audioData = Object.assign(
            {},
            { ...this.theAudio, userID: this.user.id }
          )
          const ref = firebase.database().ref(`org/${this.orgID}/audio`).push()
          audioData.key = ref.key
          ref.update(audioData)
        }
      }
      firebase
        .database()
        .ref(thePath + "/audio/" + this.theAudio.key)
        .update(this.theAudio)
      this.edit = false
      this.copyToUserAudio = false
    },
    deleteAudio() {
      var thePath
      if (this.modalFrom === "userAudio") {
        thePath = "org/" + this.orgID
      } else {
        thePath = "org/" + this.orgID + "/game/" + this.gameID
      }
      firebase
        .database()
        .ref(thePath + "/audio/" + this.theAudio.key)
        .remove()
      this.edit = false
      this.copyToUserAudio = false
    },
    loadAudio() {
      var thePath = "org/" + this.orgID + "/game/" + this.gameID

      firebase
        .database()
        .ref(thePath + "/audio")
        .on("value", snapshot => {
          this.audioArr = snapshot.val()
        })
      thePath = "org/" + this.orgID
      var theUserID
      if (this.user) {
        theUserID = this.user.id
      } else {
        theUserID = 44
      }
      firebase
        .database()
        .ref(thePath + "/audio")
        .orderByChild("userID")
        .equalTo(theUserID)
        .on("value", snapshot => {
          this.audioUserArr = snapshot.val()
        })
    },
    detectFiles(fileList) {
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x])
      })
    },
    async upload(file) {
      console.log(file)
      const theTime = new Date()
      const fileName = "audio/" + this.gameID + "/" + file.name + theTime
      const downloadURL = await uploadMedia({
        fileName,
        blob: file,
        token: this.token,
        axiosConfig: {
          onUploadProgress: progressEvent => {
            this.progressUpload = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
          }
        }
      })
      console.log(downloadURL)
      let obj = {}
      obj.source = downloadURL
      obj.name = this.audioname
      if (this.userSound) {
        if (this.user) {
          obj.userID = this.user.id
        } else {
          obj.userID = 44
        }
      }
      obj.volume = 100
      let thePath
      if (this.userSound) {
        thePath = "org/" + this.orgID
      } else {
        thePath = "org/" + this.orgID + "/game/" + this.gameID
      }
      console.log(thePath)
      firebase
        .database()
        .ref(thePath + "/audio")
        .push(obj)
      // this.$emit('url', downloadURL)
      // this.audioArr.push(obj)
      this.uploadModal = false
    },
    audioFinish() {
      alert("finn audio")
    },
    start() {
      var player = this.$refs.audio.player
      console.log(player)
      player.play()
    },
    setAudio(audio, index) {
      var payload = {}
      this.theAudio = audio
      this.theAudio.key = index
      this.activeBtn = index
      this.audioname = audio.name
      payload.playing = true
      payload.source = audio.source
      payload.volume = audio.volume
      payload.name = audio.name
      payload.key = index
      this.$store.dispatch("updateAudioPlayer", payload)
      var player = this.$refs.audio.player
      player.source = {
        type: "audio",
        title: audio.name,
        sources: [
          {
            src: audio.source,
            type: "audio/mp3"
          }
        ]
      }
      player.volume = audio.volume / 100
      player.play()

      player.on("volumechange", () => {
        console.log("Volume")
        this.theAudio.volume = player.volume * 100
        var payload = {}
        payload.volume = player.volume * 100
        this.$store.dispatch("updateAudioPlayer", payload)
        // this.saveAudio()
      })
      player.on("playing", () => {
        console.log("playing at time" + player.currentTime)
        this.theAudio.playing = true
        var payload = {}
        payload.playing = true
        payload.currentTime = player.currentTime
        this.$store.dispatch("updateAudioPlayer", payload)
        // this.saveAudio()
      })
      player.on("pause", () => {
        console.log("pause at time" + player.currentTime)
        this.theAudio.playing = false
        var payload = {}
        payload.playing = false
        payload.currentTime = player.currentTime
        this.$store.dispatch("updateAudioPlayer", payload)
        // this.saveAudio()
      })
    }
  }
}
</script>

<style lang="scss">
.audio-button {
  float: left;
}
.audio-buttons {
  clear: both;
}
#audio-player {
  float: left;
  width: 100%;
}
#upload {
  float: left;
}
.activeSound {
  background-color: rgba($color-green, 0.5) !important;
}
</style>

import { render, staticRenderFns } from "./Edit.vue?vue&type=template&id=17841aa7&"
import script from "./Edit.vue?vue&type=script&lang=js&"
export * from "./Edit.vue?vue&type=script&lang=js&"
import style0 from "./Edit.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VCheckbox,VDataTable,VDialog,VFlex,VIcon,VLayout,VRadio,VRadioGroup,VSelect,VSpacer,VTextField,VTextarea,VToolbar,VToolbarTitle})


/* hot reload */
if (module.hot) {
  var api = require("/Users/finnkelly/Code/Go Remote/remote-chat-finn/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('17841aa7')) {
      api.createRecord('17841aa7', component.options)
    } else {
      api.reload('17841aa7', component.options)
    }
    module.hot.accept("./Edit.vue?vue&type=template&id=17841aa7&", function () {
      api.rerender('17841aa7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Game/Edit.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { attrs: { center: "" } },
    [
      _c("vue-plyr", { ref: "audio", attrs: { id: "audio-player" } }, [
        _c("audio", [
          _c("source", { attrs: { src: _vm.audioSrc, type: "audio/mp3" } })
        ])
      ]),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "300px" },
          model: {
            value: _vm.uploadModal,
            callback: function($$v) {
              _vm.uploadModal = $$v
            },
            expression: "uploadModal"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { height: "350" } },
            [
              _c(
                "v-card-title",
                { attrs: { "primary-title": "" } },
                [
                  _c("div", { attrs: { id: "dialogue-card" } }, [
                    _c("h1", { staticClass: "headline center" }, [
                      _vm._v("Upload Audio")
                    ]),
                    _c("br")
                  ]),
                  _c("v-text-field", {
                    attrs: {
                      id: "audioname",
                      name: "audioname",
                      label: "Audio Name",
                      type: "text",
                      required: ""
                    },
                    model: {
                      value: _vm.audioname,
                      callback: function($$v) {
                        _vm.audioname = $$v
                      },
                      expression: "audioname"
                    }
                  }),
                  _c("input", {
                    attrs: { type: "file", accept: ".m4a,.mp3,.ogg" },
                    on: {
                      change: function($event) {
                        return _vm.detectFiles($event.target.files)
                      }
                    }
                  }),
                  _c("div", {
                    staticClass: "progress-bar",
                    style: { width: _vm.progressUpload + "%" }
                  }),
                  _c("v-progress-linear", {
                    model: {
                      value: _vm.progressUpload,
                      callback: function($$v) {
                        _vm.progressUpload = $$v
                      },
                      expression: "progressUpload"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "350px" },
          model: {
            value: _vm.edit,
            callback: function($$v) {
              _vm.edit = $$v
            },
            expression: "edit"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { pa4: "" } },
            [
              _c("v-card-title", { attrs: { "primary-title": "" } }, [
                _c(
                  "div",
                  { attrs: { id: "dialogue-card" } },
                  [
                    _c("h1", { staticClass: "headline center" }, [
                      _vm._v("Edit Audio")
                    ]),
                    _c("br"),
                    _c("v-text-field", {
                      attrs: {
                        id: "audioname",
                        name: "audioname",
                        label: "Audio Name",
                        type: "text",
                        required: ""
                      },
                      model: {
                        value: _vm.theAudio.name,
                        callback: function($$v) {
                          _vm.$set(_vm.theAudio, "name", $$v)
                        },
                        expression: "theAudio.name"
                      }
                    }),
                    _c("v-text-field", {
                      attrs: {
                        id: "audioname",
                        name: "keypress",
                        label: "Keypress",
                        type: "text",
                        required: ""
                      },
                      model: {
                        value: _vm.theAudio.keypress,
                        callback: function($$v) {
                          _vm.$set(_vm.theAudio, "keypress", $$v)
                        },
                        expression: "theAudio.keypress"
                      }
                    }),
                    _c("v-text-field", {
                      attrs: {
                        id: "audioname",
                        name: "color",
                        label: "Hex Color",
                        type: "text"
                      },
                      model: {
                        value: _vm.theAudio.color,
                        callback: function($$v) {
                          _vm.$set(_vm.theAudio, "color", $$v)
                        },
                        expression: "theAudio.color"
                      }
                    }),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("v-slider", {
                          attrs: { label: "volume" },
                          model: {
                            value: _vm.theAudio.volume,
                            callback: function($$v) {
                              _vm.$set(_vm.theAudio, "volume", $$v)
                            },
                            expression: "theAudio.volume"
                          }
                        })
                      ],
                      1
                    ),
                    _c("v-checkbox", {
                      attrs: {
                        label:
                          _vm.modalFrom !== "userAudio"
                            ? "Copy to user audio"
                            : "Copy to game audio "
                      },
                      model: {
                        value: _vm.copyToUserAudio,
                        callback: function($$v) {
                          _vm.copyToUserAudio = $$v
                        },
                        expression: "copyToUserAudio"
                      }
                    }),
                    _vm.copyToUserAudio
                      ? _c(
                          "v-flex",
                          [
                            _c("v-input", {
                              attrs: { label: "Game", required: "" },
                              model: {
                                value: _vm.copyToGameID,
                                callback: function($$v) {
                                  _vm.copyToGameID = $$v
                                },
                                expression: "copyToGameID"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function($event) {
                              _vm.edit = false
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary",
                          on: {
                            click: function($event) {
                              return _vm.saveAudio()
                            }
                          }
                        },
                        [_vm._v("Save")]
                      ),
                      _c("br"),
                      _c("br")
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.deleteAudio()
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("delete")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div"),
      _c("br", { staticStyle: { clear: "both" } }),
      _c(
        "v-toolbar",
        { attrs: { color: "brown darken-3", dark: "", height: "40" } },
        [
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  _vm.audioname = ""
                  _vm.userSound = false
                  _vm.uploadModal = true
                  _vm.theAudio = {}
                  _vm.progressUpload = 0
                }
              }
            },
            [_c("v-icon", [_vm._v("add")])],
            1
          ),
          _c("v-toolbar-title", [_vm._v("Game Music Clips")]),
          _c("v-spacer"),
          _c(
            "v-btn",
            { attrs: { icon: "" } },
            [_c("v-icon", [_vm._v("search")])],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        {
          staticClass: "audio-button",
          attrs: { "min-height": "100", width: "100%" }
        },
        _vm._l(_vm.audioArr, function(item, index) {
          return _c(
            "v-btn",
            {
              key: index,
              class: { activeSound: _vm.activeBtn === index },
              style: "background-color: " + item.color + "; margin: 3px",
              attrs: { dark: "" },
              on: {
                click: [
                  function($event) {
                    if (!$event.shiftKey) {
                      return null
                    }
                    return _vm.editAudio(item, index, "gameAudio")
                  },
                  function($event) {
                    if (!$event.altKey) {
                      return null
                    }
                    return _vm.editAudio(item, index, "gameAudio")
                  },
                  function($event) {
                    if (
                      $event.ctrlKey ||
                      $event.shiftKey ||
                      $event.altKey ||
                      $event.metaKey
                    ) {
                      return null
                    }
                    return _vm.setAudio(item, index)
                  }
                ]
              },
              model: {
                value: item.source,
                callback: function($$v) {
                  _vm.$set(item, "source", $$v)
                },
                expression: "item.source"
              }
            },
            [_vm._v(" " + _vm._s(item.name) + " ")]
          )
        }),
        1
      ),
      _c("br", { staticStyle: { clear: "both" } }),
      _c(
        "v-toolbar",
        { attrs: { color: "purple darken-3", dark: "", height: "40" } },
        [
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  _vm.audioname = ""
                  _vm.userSound = true
                  _vm.uploadModal = true
                  _vm.theAudio = {}
                  _vm.progressUpload = 0
                }
              }
            },
            [_c("v-icon", [_vm._v("add")])],
            1
          ),
          _c("v-toolbar-title", [_vm._v("My User Clips")]),
          _c("v-spacer"),
          _c(
            "v-btn",
            { attrs: { icon: "" } },
            [_c("v-icon", [_vm._v("search")])],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        {
          staticClass: "audio-button",
          attrs: { "min-height": "100", width: "100%" }
        },
        _vm._l(_vm.audioUserArr, function(item, index) {
          return _c(
            "v-btn",
            {
              key: index,
              class: { activeSound: _vm.activeBtn === index },
              style: "background-color: " + item.color + "; margin: 3px",
              attrs: { dark: "" },
              on: {
                click: [
                  function($event) {
                    if (!$event.altKey) {
                      return null
                    }
                    return _vm.editAudio(item, index, "userAudio")
                  },
                  function($event) {
                    if (!$event.shiftKey) {
                      return null
                    }
                    return _vm.editAudio(item, index, "userAudio")
                  },
                  function($event) {
                    if (
                      $event.ctrlKey ||
                      $event.shiftKey ||
                      $event.altKey ||
                      $event.metaKey
                    ) {
                      return null
                    }
                    return _vm.setAudio(item, index)
                  }
                ]
              },
              model: {
                value: item.source,
                callback: function($$v) {
                  _vm.$set(item, "source", $$v)
                },
                expression: "item.source"
              }
            },
            [_vm._v(" " + _vm._s(item.name) + " ")]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
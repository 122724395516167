<template>
  <v-card>
    <v-card-title>
      <h2 class="black--text">
        {{ game.name }} - {{ game.description }}
        <v-btn icon class="sortHandle" @click="pushToGame"
          ><v-icon>flight_takeoff</v-icon></v-btn
        >
        <v-btn icon class="sortHandle" @click="pushToGameUsers"
          ><v-icon>face</v-icon></v-btn
        >
        <v-btn v-if="false" icon class="sortHandle" @click="turnOffVideo()"
          ><v-icon>visibility_off</v-icon></v-btn
        >
      </h2>
      <v-spacer />

      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      />
    </v-card-title>

    <v-btn circle absolute top small right fab @click="add()" color="orange">
      <v-icon>add</v-icon>
    </v-btn>
    <v-btn
      circle
      absolute
      top
      small
      right
      fab
      @click="showInstructions()"
      color="blue"
    >
      <v-icon>flash_on</v-icon>
    </v-btn>

    <v-btn
      circle
      absolute
      top
      small
      left
      fab
      color="orange"
      @click="isMisssionAddModalVisible = true"
    >
      <v-icon>add</v-icon>
    </v-btn>

    <v-data-table
      ref="sortableTable"
      :headers="headers"
      :items="allMissions"
      :search="search"
      class="elevation-1"
      item-key="theKey"
      length="10"
      dense
    >
      <template slot="items" slot-scope="props">
        <!--
          NOTE:  You'll need a unique ID, that is specific to the given item, for the key.
          Not providing a unique key that's bound to the item object will break drag and drop sorting.
          The itemKey method will return a uid for a given object using WeakMap.
          You could just use a property in the object with a unique value, like "props.item.name" in this case,
          but often getting a unique value from the object's properties can be difficult, like when adding new rows,
          or when the unique field is open to editing, etc.
        -->
        <tr
          :id="behaviorSafe(props.item.behavior)"
          :key="itemKey(props.item)"
          class="sortableRow"
          @click="props.expanded = !props.expanded"
        >
          <td class="px-1" style="width: 0.1%">
            <v-btn style="cursor: move" icon class="sortHandle"
              ><v-icon>drag_handle</v-icon></v-btn
            >
          </td>
          <td class="text-xs-left">{{ props.item.pos }}</td>
          <td class="text-xs-left">{{ props.item.name }}</td>
          <td class="text-xs-left">{{ props.item.behavior }}</td>
          <td class="text-xs-left">{{ props.item.points }}</td>
          <td class="text-xs-left">
            {{ props.item.instructions.substring(0, instructionsLength) }}
          </td>
          <td class="text-xs-left">
            {{ formatPlayType(props.item.playType) }}
          </td>
          <td class="text-xs-left">{{ props.item.numOfTries }}</td>

          <td class="text-xs-left">
            {{ modeLetters(props.item) }}
          </td>
          <td class="text-xs-left">{{ props.item.time }}</td>
          <td class="text-xs-center" nowrap>
            <v-btn link color="white" light small fab @click="edit(props.item)">
              <v-icon>edit</v-icon>
            </v-btn>
            <v-btn link color="white" light small fab @click="copy(props.item)">
              <v-icon>open_in_new</v-icon>
            </v-btn>
            <v-btn
              link
              color="white"
              light
              small
              fab
              @click="openRemoveDialog(props.item)"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        From {{ pageStart }} to {{ pageStop }}
      </template>
    </v-data-table>

    <v-dialog v-model="deleting" max-width="500px">
      <v-card>
        <v-card-title>Delete Mission</v-card-title>
        <v-card-text>Are you sure want to delete this mission?</v-card-text>
        <v-card-actions>
          <v-layout justify-center>
            <v-btn flat class="error" @click="remove">Remove</v-btn>
            <v-btn flat class="secondary" @click="deleting = false"
              >Cancel</v-btn
            >
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <mission-add-modal
      v-model="isMisssionAddModalVisible"
      :missions="allMissions"
      @create="onCreateMission"
    />

    <v-dialog scrollable persistent v-model="editing" width="850px">
      <v-card>
        <v-card-text>
          <v-toolbar color="highlight" dark>
            <v-icon dark>edit</v-icon>
            <v-toolbar-title> {{ mission.name }}</v-toolbar-title>
            <v-spacer />
            <v-spacer />
            <v-btn icon @click="update">
              <v-icon>saves</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="
                editing = false
                copying = false
              "
            >
              <v-icon>cancel</v-icon>
            </v-btn>
          </v-toolbar>
          <div v-if="mission">
            <v-layout row wrap>
              <v-flex xs12 mx-2>
                <v-checkbox
                  v-for="(mode, i) in allModes(mission)"
                  class="missionCB"
                  v-model="mission[mode.name]"
                  :label="mode.name"
                  :key="i"
                />
              </v-flex>
              <v-flex xs12 sm5 mx-2>
                <v-text-field v-model="mission.name" label="Name" required />
              </v-flex>

              <v-flex xs12 sm5 mx-2>
                <v-text-field v-model="mission.title" label="Title" required />
              </v-flex>
              <v-flex xs12 sm1 mx-2>
                <v-text-field
                  v-model="mission.points"
                  label="Points"
                  required
                />
              </v-flex>

              <v-flex xs12 mx-2 class="instruction">
                <v-textarea
                  v-model="mission.instructions"
                  name="instructions"
                  rows="3"
                  label="Instructions"
                  required
                />
              </v-flex>
              <v-flex xs6 mx-2 style="margin-top: -50px">
                <v-text-field v-model="mission.notes" label="Notes" notes />
              </v-flex>
              <v-flex xs2 style="margin-top: -50px">
                <v-checkbox v-model="mission.passScribe" label="Pass Scribe" />
              </v-flex>
              <v-flex xs2 style="margin-top: -50px">
                <v-checkbox v-model="mission.noVote" label="No Vote" />
              </v-flex>

              <v-flex xs12 sm3 mx-2>
                <v-select
                  :items="behaviors"
                  v-model="mission.behavior"
                  @change="defaultModes($event)"
                  label="Behaviors"
                  required
                />
              </v-flex>
              <v-flex xs12 sm1 mx-2>
                <v-text-field
                  v-model="mission.time"
                  label="Time Limit (seconds)"
                  required
                />
              </v-flex>
              <v-flex xs12 sm4 mx-2>
                <v-select
                  :items="playTypes"
                  v-model="mission.playType"
                  label="Play Types"
                  required
                />
              </v-flex>
              <v-flex xs12 sm2 mx-2>
                <v-select
                  :items="tries"
                  v-model="mission.numOfTries"
                  label="Max Tries"
                />
              </v-flex>
              <v-flex xs12 mx-5 v-if="mission.behavior == 'Fact Match'">
                <v-select
                  :items="freeFormMissions"
                  item-text="name"
                  item-value="id"
                  v-model="mission.freeFormMissionID"
                  label="Connect to Free Form, Photo, Take Photo or Giphy"
                />
              </v-flex>
              <v-flex
                xs8
                mx-2
                v-if="
                  mission.behavior === 'Take Photo' ||
                  mission.behavior === 'Photo'
                "
              >
                <v-checkbox
                  v-model="mission.useAsAvatar"
                  label="Use for Avatar"
                />
              </v-flex>
              <v-flex xs8 mx-2 v-if="mission.behavior === 'Link'">
                <v-text-field v-model="mission.answer" label="Link URL" />
              </v-flex>
              <v-flex
                xs3
                style="margin-top: -5px"
                v-if="mission.behavior === 'Link'"
              >
                <v-checkbox
                  v-model="mission.showWinningTeam"
                  label="Show Winning Team"
                />
              </v-flex>

              <transition name="fade">
                <v-flex xs12 mx-2>
                  <v-text-field
                    v-if="
                      mission.behavior === 'Text' ||
                      mission.behavior === 'Draw: Pictionary' ||
                      mission.behavior === 'Categories' ||
                      mission.behavior === 'Lipdub'
                    "
                    v-model="mission.answer"
                    label="Answer"
                  />
                </v-flex>
              </transition>

              <v-flex
                v-if="
                  mission.behavior === 'Multiple Choice' ||
                  mission.behavior === 'Poll'
                "
                xs12
                mx-2
              >
                <v-layout row>
                  <v-flex v-if="mission.behavior === 'Multiple Choice'" xs10>
                    <v-text-field v-model="mission.answer1" label="Answer 1" />
                    <v-text-field v-model="mission.answer2" label="Answer 2" />
                    <v-text-field v-model="mission.answer3" label="Answer 3" />
                    <v-text-field v-model="mission.answer4" label="Answer 4" />
                    <v-text-field v-model="mission.answer5" label="Answer 5" />
                  </v-flex>
                  <v-flex v-else xs10>
                    <v-text-field v-model="mission.answer1" label="Option 1" />
                    <v-text-field v-model="mission.answer2" label="Option 2" />
                    <v-text-field v-model="mission.answer3" label="Option 3" />
                    <v-text-field v-model="mission.answer4" label="Option 4" />
                    <v-text-field v-model="mission.answer5" label="Option 5" />
                  </v-flex>
                  <v-flex
                    v-if="mission.behavior === 'Multiple Choice'"
                    xs2
                    mx-3
                  >
                    <v-radio-group v-model="mission.multiCorrect">
                      <v-radio
                        v-for="n in 5"
                        :key="n"
                        :value="n"
                        my-5
                        class="correct"
                        label="correct"
                      />
                    </v-radio-group>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs2 mx-2>
                <v-text-field v-model="mission.youtube" label="YouTube Link" />
              </v-flex>
              <v-flex xs3 mx-2>
                <v-text-field
                  v-model="mission.videoTime"
                  label="Start time (secs)"
                />
              </v-flex>
              <v-flex xs3 mx-2>
                <image-uploader
                  :debug="1"
                  :max-width="1200"
                  :quality="1"
                  :auto-rotate="true"
                  :preview="false"
                  :class-name="[
                    'fileinput',
                    { 'fileinput--loaded': mission.photo }
                  ]"
                  output-format="verbose"
                  capture="environment"
                  accept=".gif,.jpg,.png,.jpeg"
                  @input="setImage($event, game)"
                >
                </image-uploader>
                <v-text-field v-model="mission.photo" label="Photo URL" />
              </v-flex>
              <v-flex xs3 mx-2>
                <image-uploader
                  :debug="1"
                  :max-width="600"
                  :quality="1"
                  :auto-rotate="true"
                  :preview="false"
                  :class-name="[
                    'fileinput',
                    { 'fileinput--loaded': mission.instructionsPhoto }
                  ]"
                  output-format="verbose"
                  capture="environment"
                  accept=".gif,.jpg,.png,.jpeg"
                  @input="setInstructionsImage($event, game)"
                >
                </image-uploader>
                <v-text-field
                  v-model="mission.instructionsPhoto"
                  label="Instructions Image or Gif"
                />
              </v-flex>
              <v-flex v-if="true" xs2 mx-2>
                <v-select
                  :items="gameAudio"
                  v-model="mission.audio"
                  item-text="name"
                  item-value="key"
                  label="Audio"
                />
              </v-flex>

              <v-flex v-if="copying && user.super" xs12 mx-2>
                <v-select
                  v-model="newOrgID"
                  :items="orgs"
                  label="Choose Org"
                  item-text="name"
                  item-value="id"
                  @change="updateOrgID"
                />
              </v-flex>
              <v-flex v-if="copying" xs12 mx-2>
                <!-- <v-autocomplete
                  v-model="newGameID"
                  :items="games"
                  color="white"
                  hide-no-data
                  hide-selected
                  item-text="name"
                  item-value="id"
                  label="Copy To Game"
                  placeholder="Start typing to Search"
                  return-object
                ></v-autocomplete> -->
                <!-- <v-select
                  v-model="newGameID"
                  :items="games"
                  label="Copy To Game"
                  item-text="name"
                  item-value="id"
                /> -->
              </v-flex>
            </v-layout>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-layout justify-center>
            <v-btn v-if="copying" flat class="primary" @click="update"
              >Copy</v-btn
            >
            <v-btn
              v-else
              flat
              :loading="loadingImage"
              class="primary"
              @click="update"
              >Save</v-btn
            >
            <v-btn
              flat
              class="secondary"
              @click="
                editing = false
                copying = false
              "
              >Cancel</v-btn
            >
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-flex>
      <Audio xs12 />
    </v-flex>
  </v-card>
</template>

<script>
import Sortable from "sortablejs"
import Audio from "@/components/GroupTeams/Common/Audio"
import MissionAddModal from "@/components/Game/MissionAddModal.vue"
import uniqid from "uniqid"
import { mapState, mapGetters } from "vuex"
import { uploadMedia, getBlobFromDataURL } from "@/services/storage.service"
import firebase from "firebase/app"

export default {
  metaInfo: {
    title: "Missions"
  },
  components: {
    Audio,
    MissionAddModal
  },
  filters: {
    truncate: function (value, limit) {
      if (value.length > limit) {
        value = value.substring(0, limit - 3) + "..."
      }
      return value
    }
  },
  data() {
    return {
      instructionsLength: 140,
      itemKeys: new WeakMap(),
      currentItemKey: 0,
      tries: ["Unlimited", "1", "2", "3", "4", "5"],
      mission: {
        name: "",
        title: "",
        instruction: "",
        behavior: "",
        answer: ""
      },
      oldGameID: this.$store.getters.gameID,
      newGameID: this.$store.getters.gameID,
      newOrgID: this.$store.getters.orgID,
      oldOrgID: this.$store.getters.orgID,
      viewing: false,
      items: [],
      deleting: false,
      editing: false,
      copying: false,
      adding: false,
      tmp: "",
      search: "",
      pagination: {},
      loadingImage: false,
      behavior: "",
      typicalModes: this.$store.getters.typicalModes,
      behaviors: this.$store.getters.behaviors,
      playTypes: this.$store.getters.playTypes,
      playType: "Team: Speed Matters",
      headers: [
        {
          sortable: false
        },
        {
          text: "#",
          value: "pos",
          align: "left",
          sortable: true
        },
        {
          text: "Mission",
          value: "avatar",
          align: "left",
          sortable: false
        },
        {
          text: "Type",
          value: "name",
          align: "left",
          sortable: true
        },
        {
          text: "Points",
          value: "points",
          align: "left",
          sortable: true
        },
        {
          text: "Instructions",
          value: "instructions",
          align: "left",
          sortable: false
        },
        {
          text: "Play Type",
          value: "playType",
          align: "left",
          sortable: false
        },
        {
          text: "Tries",
          value: "tries",
          align: "left",
          sortable: false
        },
        {
          text: "Modes",
          value: "modeLetters",
          align: "left",
          sortable: false
        },
        {
          text: "Timer",
          value: "time",
          align: "left",
          sortable: true
        },
        {
          text: "Action",
          value: "action",
          align: "center",
          sortable: false
        }
      ],
      isMisssionAddModalVisible: false
    }
  },
  computed: {
    ...mapState("group", ["modes"]),
    ...mapGetters("auth", ["token"]),
    ...mapGetters(["game", "user", "gameID", "urlID"]),
    allMissions() {
      let arr = Object.values(this.$store.getters.missions)
      //console.log('load data')
      let obj = this.$store.getters.missions
      // console.log(obj)
      let i = 0
      for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          if (key.length > 10) {
            Object.assign(arr[i], { theKey: key })
          }
        }
        i++
      }
      // this removes missions that don't havea a behavior meaning that they are corrupt in some way
      // console.log("ALL MISSIONS")
      var badArr = arr.filter(m => {
        return !m.behavior
      })
      // console.log(badArr)
      for (let i in badArr) {
        this.$store.dispatch("removeMission", badArr[i])
        // console.log(arr[i].name)
      }
      // console.log(arr)
      return arr.sort((a, b) => a.pos - b.pos)
    },
    freeFormMissions() {
      var arr = this.allMissions.filter(item => {
        return (
          item.behavior === "Freeform" ||
          item.behavior === "Take Photo" ||
          item.behavior === "Photo" ||
          item.behavior === "Giphy"
        )
      })
      var newArr = []
      console.log("FREE FORMS")
      console.log(arr)
      for (var i in arr) {
        var obj = {}
        obj.id = arr[i].theKey
        obj.name = arr[i].name
        newArr.push(obj)
      }
      return newArr
    },
    orgs() {
      var arr = Object.entries(this.$store.getters.orgs)
      console.log("ORGS")
      console.log(arr)
      var newArr = []
      for (var i in arr) {
        var obj = {}
        obj.id = arr[i][0]
        obj.name = arr[i][1].name
        newArr.push(obj)
      }
      return newArr
    },
    numOfPlayers() {
      var p = []
      p.push("Does Not Matter")
      for (var i = 1; i < 7; i++) {
        p.push(i)
      }
      return p
    },
    gameAudio() {
      var gameAudio = this.$store.getters.gameAudio
      if (gameAudio) {
        var arr = Object.values(this.$store.getters.gameAudio)
        var newArr = []
        newArr.push({ key: null, name: "nothing" })
        for (var i = 0; i < arr.length; i++) {
          // console.log(arr[i].name)
          newArr.push({ key: arr[i].source, name: arr[i].name })
        }
        return newArr
      }
      return []
    }
  },
  watch: {
    editing(to) {
      this.cancel(to)
    },
    viewing(to) {
      this.cancel(to)
    },
    deleting(to) {
      this.cancel(to)
    }
  },
  async created() {
    // await this.$store.dispatch("subscribeToOrgs")
    await this.$store.dispatch("subscribeToGameAudio")
  },
  mounted() {
    /* eslint-disable no-new */
    this.$refs.sortableTable.defaultPagination.rowsPerPage = 300
    new Sortable(
      this.$refs.sortableTable.$el.getElementsByTagName("tbody")[0],
      {
        draggable: ".sortableRow",
        handle: ".sortHandle",
        onEnd: this.dragReorder
      }
    )
  },
  methods: {
    showInstructions() {
      this.instructionsLength = 1000
    },
    removePhoto() {
      this.mission.photo = null
    },
    async setImage(e) {
      try {
        const blob = await getBlobFromDataURL(e.dataUrl)
        this.loadingImage = true
        const fileName = `gamephotos/${uniqid()}-${this.mission.name}.png`
        this.mission.photo = await uploadMedia({
          fileName,
          blob,
          token: this.token
        })
        console.log("which type = " + this.mission.photo)
        this.loadingImage = false
      } catch (error) {
        console.log(error)
      }
    },
    async setInstructionsImage(e) {
      try {
        const blob = await getBlobFromDataURL(e.dataUrl)
        this.loadingImage = true
        const fileName = `gamephotos/${uniqid()}-${this.mission.name}.png`
        this.mission.instructionsPhoto = await uploadMedia({
          fileName,
          blob,
          token: this.token
        })
        console.log("which type = " + this.mission.instructionsPhoto)
        this.loadingImage = false
      } catch (error) {
        console.log(error)
      }
    },
    formatPlayType(PT) {
      if (PT == "Team: Speed Matters") return "Team: Speed"
      if (PT == "Team: Speed Does Not Matter") return "Team"
      if (PT == "Individual: Speed Does Not Matter") return "Indy Speed"
      if (PT == "Individual: Speed Matters") return "Indy"
    },
    updateOrgID(orgID) {
      this.$store.dispatch("setOrgID", orgID)
    },
    modeLetters(mission) {
      var modes = Object.values(this.modes)
      var result = ""
      for (var i in modes) {
        if (mission[modes[i]]) {
          result = result + modes[i].charAt(0).toUpperCase()
        }
      }
      var typicalModes = this.typicalModes[mission.behavior]
      if (this.missionHasNoModes(mission)) {
        for (var j in typicalModes) {
          // console.log(typicalModes[j])
          result = result + typicalModes[j].charAt(0).toUpperCase()
        }
      }
      return result
    },
    defaultModes(behavior) {
      // console.log('typical Modes')
      // console.log(this.typicalModes[behavior])
      // console.log(this.mission)
      var typicalModes = this.typicalModes[behavior]
      //console.log(typicalModes)
      var modes = Object.values(this.modes)
      for (var i in modes) {
        this.mission[modes[i]] = false
      }
      for (var j in typicalModes) {
        // console.log(typicalModes[j])
        this.mission[typicalModes[j].toLowerCase()] = true
      }
    },
    missionHasNoModes(mission) {
      var modes = Object.values(this.modes)
      var hasNoModes = true
      for (var i in modes) {
        // console.log(modes[i])
        // console.log(mission[modes[i]])
        if (mission[modes[i]]) {
          hasNoModes = false
        }
      }
      return hasNoModes
    },
    allModes(mission) {
      var arr = Object.values(this.modes)
      var newArr = []
      for (var i in arr) {
        var obj = {}
        obj.name = arr[i]
        obj.value = mission[arr[i]]
        newArr.push(obj)
      }
      return newArr
    },
    behaviorSafe(m) {
      if (m.length > 3) {
        return "list-" + m.replace(/\s+/g, "-").toLowerCase().replace(":", "")
      }
    },
    async pushToGame() {
      const room = this.game.started ? "game" : "lobby"
      await this.$router.push(`/${room}/${this.urlID}`)
    },
    async pushToGameUsers() {
      await this.$router.push(`/game/${this.urlID}/users`)
    },
    async dragReorder({ oldIndex, newIndex }) {
      const missions = [...this.allMissions]
      const target = missions.splice(oldIndex, 1)[0]
      missions.splice(newIndex, 0, target)
      const reordered = missions.map((mission, i) => ({ ...mission, pos: i }))

      const update = {}

      reordered.forEach(({ theKey: missionID, pos }) => {
        update[`${missionID}/pos`] = pos
      })

      const gameID = this.$store.getters.gameID
      const orgID = this.$store.getters.orgID

      await firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/missions`)
        .update(update)
    },
    itemKey(item) {
      if (!this.itemKeys.has(item))
        this.itemKeys.set(item, ++this.currentItemKey)
      return this.itemKeys.get(item)
    },
    resetForm() {
      this.mission = {
        name: "",
        avatar: ""
      }
    },
    cancel(to) {
      if (to === false) {
        this.resetForm()
      }
    },
    view(mission) {
      this.viewing = true
      this.mission = mission
    },
    closeModalDetail() {
      this.viewing = false
      this.mission = null
    },
    openRemoveDialog(mission) {
      this.mission = mission
      this.deleting = true
    },
    remove() {
      this.$store.dispatch("removeMission", this.mission)
      this.deleting = false
    },
    edit(mission) {
      console.log("EDIT", mission)
      this.mission = mission
      console.log(this.missionHasNoModes(mission))
      if (this.missionHasNoModes(mission)) {
        this.defaultModes(mission.behavior)
      }
      this.copying = false
      this.editing = true
    },
    copy(mission) {
      this.copying = true
      this.editing = true
      this.mission = mission
    },
    add() {
      this.editing = true
      this.adding = true
      this.mission = {
        name: "",
        title: "",
        instructions: "",
        behavior: "",
        answer: "",
        numOfPlayers: "",
        gameID: this.gameID,
        video: "",
        photo: "",
        time: 0,
        pos: 0,
        points: 0,
        multiCorrect: 1
      }
    },
    async update() {
      this.mission.gameID = this.newGameID
      this.mission.orgID = this.newOrgID
      // console.log("Mission audio", this.mission.audio)
      if (this.adding || this.copying) {
        if (!this.copying) {
          this.mission.pos = this.allMissions.length + 1
        }
        console.log("ADD OR COPYING", this.newGameID.id)
        await this.$store.dispatch("addToMissions", {
          array: [this.mission],
          gameID: this.newGameID.id,
          orgID: this.newOrgID
        })
      } else {
        await this.$store.dispatch("updateMission", this.mission)
      }
      this.editing = false
      this.adding = false
      await this.$store.dispatch("setOrgID", this.oldOrgID)
    },
    getMission(id) {
      const mission = this.allMissions.find(mission => mission.theKey === id)
      if (mission === undefined)
        throw new Error(`[Edit]: there is no mission with ${id} id`)
      return mission
    },
    async onCreateMission({ afterID, mission }) {
      const afterMission = this.getMission(afterID)
      const { orgID, gameID } = this.$store.getters

      mission.pos = afterMission.pos + 1

      const ref = await firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/missions`)

      const newID = ref.push().key

      const snapshot = await ref.once("value")

      const val = snapshot.val()

      for (const key in val) {
        if (val[key].pos > afterMission.pos) {
          val[key].pos++
        }
      }

      val[newID] = mission

      await ref.update(val)
    }
  }
}
</script>

<style>
#list-info {
  background-color: rgb(241, 241, 224);
}

#list-text {
  background-color: rgb(221, 211, 250);
}
#list-team-name {
  background-color: rgb(244, 223, 253);
}
#list-take-photo {
  background-color: rgb(229, 253, 223);
}
#list-photo {
  background-color: rgb(229, 253, 223);
}
#list-multiple-choice {
  background-color: rgb(253, 229, 223);
}
#list-freeform {
  background-color: rgb(221, 211, 250);
}
#list-lipdub {
  background-color: rgb(183, 164, 240);
}
#list-buzz-in {
  background-color: rgb(171, 177, 143);
}
#list-player-rating {
  background-color: rgb(248, 235, 196);
}
#list-draw-everyone,
#list-draw-each-team,
#list-draw-pictionary {
  background-color: rgb(223, 243, 252);
}
#list-poll {
  background-color: rgb(252, 197, 247);
}
#list-url {
  background-color: rgb(252, 159, 143);
}
#list-categories {
  background-color: rgb(219, 204, 121);
}
#list-giphy {
  background-color: rgb(194, 141, 178);
}

.mission-list-item {
  font-size: 12px;
  cursor: pointer;
  font-weight: 500;
  padding: 2px 0 2px 5px;
}
.mission-list-type {
  text-align: center;
  width: 15px;
  float: right;
  background-color: rgb(245, 188, 188);
  border-radius: 50%;
}

#mission-list .active {
  background-color: #66bb6a;
}

#mission-list .played {
  background-color: #bdbcbc;
  color: #383838;
}
#mission-list .view {
  border: 1px solid #111;
}

#mission-list #team-name {
  background-color: #ebffec;
}

.mission-list-item:hover {
  background-color: rgb(178, 178, 245) !important;
}
.missionCB {
  float: left;
  margin-left: 20px;
}
</style>
